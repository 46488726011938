import { Media, spacing, typography } from '@renderbus/common/theme'
import styled from 'styled-components'
import transition from '@renderbus/common/theme/transition'
import { color } from '@renderbus/common/theme/variables'

export const FormLayout = styled.div`
  background-color: #eaf2f7;
  padding: 100px;
  ${Media.lessThan(Media.small)} {
    padding: 80px ${spacing.small};
  }
`

export const FormContainer = styled.div`
  max-width: 920px;
  margin: 0 auto;
  padding: ${spacing.base} 100px 50px;
  margin: 0 auto;
  background-color: white;
  ${Media.lessThan(Media.small)} {
    padding: ${spacing.small} ${spacing.base};
  }
`

export const FormTitle = styled.h1`
  font-size: ${typography.h3};
  font-weight: bold;
  padding: ${spacing.base} 0;
  color: #0095ff;
  text-align: center;
`

export const FormInfo = styled.div`
  padding: ${spacing.small} 0;
  line-height: ${typography.h3};
  border-bottom: 1px dashed #dce4ed;
  margin-bottom: ${spacing.small};
  > p {
    margin: 0;
  }
`

export const FormFieldContainer = styled.div`
  margin: ${spacing.base} 0;
`

export const FormFieldTitle = styled.h5`
  position: relative;
  margin: 5px 0;
  font-size: ${typography.text};
  ::before {
    content: '*';
    margin-right: 5px;
    position: absolute;
    right: 100%;
    color: red;
  }
`

export const FormFieldMessageTitle = styled.h5`
  margin: 5px 0;
  font-size: ${typography.text};
  margin-left: 30px;
`

export const FormFieldInputBox = styled.div`
  input {
    max-width: 500px;
    width: 100%;
  }
`
export const FormInputFileld = styled.input`
  width: 350px;
  margin-left: 20px;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    margin-top: 6px;
    margin-left: 0;
  }
`

export const FormFieldCheckBox = styled.div`
  position: relative;
  input {
    width: 20px;
    position: absolute;
    top: 5px;
  }
`
export const NavigationLink = styled.a`
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-decoration: none;
  font-size: ${typography.text};
  color: ${p => (p.isActive ? color.primary : '#181818')};
  transition: ${transition(['color', 'background-color'])};
  :hover {
    color: ${color.primary};
  }
`

export const FormButton = styled.div`
  margin-top: ${spacing.base};
  text-align: center;
`

export const FormTips = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 200px;
  text-align: center;
  background: white;
  svg {
    margin-right: ${spacing.small};
  }
  ${Media.lessThan(Media.small)} {
    padding: 80px ${spacing.large};
    svg {
      display: block;
      margin: ${spacing.base} auto;
    }
  }
`
