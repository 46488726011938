import React from 'react'
import styled from 'styled-components'
import { InputType } from '../../../constant/nvidia'

const RadioBox = styled.div`
  width: 100%;
  input {
    width: auto !important;
    margin: 0 5px;
  }
`

class InputField extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(e) {
    const {
      onChange,
      info: { name },
    } = this.props
    onChange(e.target.value, name)
  }
  render() {
    const { name = '', placeholder = '', type = InputType.Input, options = [] } = this.props.info
    const { value } = this.props
    switch (type) {
      case InputType.Input:
        return (
          <input value={value} onChange={this.handleChange} name={name} placeholder={placeholder} />
        )
      case InputType.Radio:
        return (
          <React.Fragment>
            {options.map((option, index) => (
              <RadioBox key={index}>
                <input
                  value={option}
                  onChange={this.handleChange}
                  name={name}
                  placeholder={placeholder}
                  type='radio'
                  id={`${name}-${option}`}
                  checked={option === value}
                />
                <label htmlFor={`${name}-${option}`}>{option}</label>
              </RadioBox>
            ))}
          </React.Fragment>
        )
      case InputType.Select:
        return (
          <select value={value} onChange={this.handleChange} name={name} placeholder={placeholder}>
            <option hidden disabled value=''>
              {placeholder}
            </option>
            {options.map((option, index) => (
              <option value={option} key={index}>
                {option}
              </option>
            ))}
          </select>
        )
      default:
        return (
          <input
            value={value}
            onChange={this.handleChange}
            name={name}
            placeholder={placeholder}
            type={type}
          />
        )
    }
  }
}

export default InputField
