export const InputType = Object.freeze({
  Input: 'Input',
  Email: 'email',
  Phone: 'phone',
  Radio: 'Radio',
  Select: 'Select',
  CheckBox: 'CheckBox',
})

export const IndustryOptions = [
  '建筑/工程/建设',
  '汽车',
  '国防/政府部分',
  '金融服务业（FSI）',
  '医疗保健',
  '高等教育/科研',
  'IT 服务业',
  '制造业',
  '媒体/娱乐',
  '石油和天然气/能源',
  '医疗影像/生命科学仪器',
  '实体零售/电商',
  '超算中心',
  '电信',
  '其他',
]
export const ProfessionOptions = [
  '首席执行官（CEO）',
  '首席信息官（CIO）',
  '首席技术官（CTO）',
  '顾问',
  '数据科学家',
  '开发者/编程人员',
  '工程师',
  '电影/视频编辑',
  '平面设计师/动画师',
  '工业设计师/产品设计师',
  'IT 管理员/系统管理员',
  'IT 经理/IT 总监/IT 负责人',
  '市场经理',
  '教授/学院指导员',
  '采购经理',
  '科研人员',
  '销售工程师',
  '销售代表/销售经理',
  '学生/研究生',
  '其他',
]
export const IsUpgradeGraphicsCardOptions = [
  '已部署',
  '考虑6个月内采购或升级',
  '可能在未来1年内考虑采购或升级',
  '无采购和升级计划',
]
export const YesOrNoOptions = ['是', '否']
export const WantDownloadEamilOptions = ['已经注册', '还未注册，愿意注册', '不感兴趣']
export const ChannelOptions = [
  '代理商或合作伙伴',
  '竞争对手',
  '技术研讨会',
  '网上信息及新闻',
  '其他',
]

export const NVIDIAList = [
  {
    name: 'name',
    placeholder: '请输入姓名',
    title: '姓名：',
    type: InputType.Input,
  },
  {
    name: 'company',
    placeholder: '请输入公司',
    title: '公司：',
    type: InputType.Input,
  },
  {
    name: 'email',
    placeholder: '请输入企业邮箱',
    title: '企业邮箱：',
    type: InputType.Email,
  },
  {
    name: 'phone',
    placeholder: '请输入手机',
    title: '手机：',
    type: InputType.Phone,
  },
  {
    name: 'industry',
    placeholder: '请选择行业',
    title: '行业：',
    type: InputType.Select,
    options: IndustryOptions,
  },
  {
    name: 'post',
    placeholder: '请选择职务',
    title: '职务：',
    type: InputType.Select,
    options: ProfessionOptions,
  },
  {
    name: 'purchasePlan',
    placeholder: '请选择',
    title: '贵司是否有采购或部署NVIDIA GPU 相关产品的计划？',
    type: InputType.Select,
    options: IsUpgradeGraphicsCardOptions,
  },
  {
    name: 'isBudget',
    placeholder: '请选择',
    title: '采购计划是否已经取得预算?',
    type: InputType.Radio,
    options: YesOrNoOptions,
  },
  {
    name: 'channel',
    placeholder: '请选择',
    title: '您是从什么渠道知道NVIDIA GPU相关产品的?',
    type: InputType.Select,
    options: ChannelOptions,
  },
  {
    name: 'isNeedHelp',
    placeholder: '请选择',
    title:
      '您是否希望与 NVIDIA 技术专家或业务代表取得联系，以针对NVIDIA GPU相关产品及技术提供更多帮助?',
    type: InputType.Radio,
    options: YesOrNoOptions,
  },
]

export const NVIDIACollectMessage = [
  {
    name: 'nvidiaEnterprise',
    placeholder: '请选择',
    title: ['请向我发送 NVIDIA 发布的有关企业的最新动态、公告及其他内容。我可以随时取消订阅。'],
    type: InputType.CheckBox,
  },
  {
    name: 'nvidiaDeveloper',
    placeholder: '请选择',
    title: ['请向我发送NVIDIA发布的有关开发者的最新动态、公告及其他内容,我可以随时取消订阅。'],
    type: InputType.CheckBox,
  },
  {
    name: 'agreeToPrivacy',
    placeholder: '请选择',
    title: [
      '填写表格即表示您同意因与我们的合作伙伴NVIDIA共同组织活动的需要而向NVIDIA分享您的数据，并同意',
      'NVIDIA隐私政策',
      '。',
    ],
    type: InputType.CheckBox,
    linkText: 'NVIDIA隐私政策',
    link: 'https://www.nvidia.cn/about-nvidia/privacy-policy/',
  },
  {
    name: 'consentData',
    placeholder: '请选择',
    title: [
      '本人同意，因调研、活动组织的必须，以及相对应的 NVIDIA 内部管理和系统操作的需要，上述信息会被传输到位于美国的 NVIDIA Corporation 按照符合',
      'NVIDIA隐私政策',
      '的方式进行存储，您可以通过发送邮件至',
      'privacy@nvidia.com',
      '进行联系以解决相关问题',
    ],
    type: InputType.CheckBox,
    linkText: 'NVIDIA隐私政策',
    ink: 'https://www.nvidia.cn/about-nvidia/privacy-policy/',
  },
]
